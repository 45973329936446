import React, { Component } from 'react'
import './style.css'
import * as rgbHex from 'rgb-hex'
import { MaterialPicker, ChromePicker } from 'react-color'
class App extends Component {
	state = {
		color: {
			r: 72,
			g: 76,
			b: 132,
			a: 1
		},
		colorHEX: "#484C84"
	}
	componentDidMount() {
		this.generatePalette()
	}
	generatePalette() {
		this.generateColor()
		let factor = 1.15
		let colorRGB = `rgb(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b})`
		let valueHEX = rgbHex(colorRGB).toUpperCase()
		// eslint-disable-next-line
		this.state.colorHEX = "#"+valueHEX
		let dr = Math.round(this.state.color.r * (2 - factor))
		let dg = Math.round(this.state.color.g * (2 - factor))
		let db = Math.round(this.state.color.b * (2 - factor))
		let darkRGB = `rgb(${dr}, ${dg}, ${db})`
		let darkHEX = rgbHex(darkRGB).toUpperCase()
		let colorDark = `#${darkHEX}`
		let lr, lg, lb
		if(this.state.color.r * factor > 255 ) {lr = 255}
		else {lr = Math.round(this.state.color.r * factor)}
		if(this.state.color.g * factor > 255 ) {lg = 255}
		else {lg = Math.round(this.state.color.g * factor)}
		if(this.state.color.b * factor > 255 ) {lb = 255}
		else {lb = Math.round(this.state.color.b * factor)}
		let lightRGB = `rgb(${lr}, ${lg}, ${lb})`
		let lightHEX = rgbHex(lightRGB).toUpperCase()
		let colorLight = `#${lightHEX}`
		let paletteDOM = document.querySelector('.palette')
		this.updateColor(paletteDOM.childNodes[0], colorLight)
		this.updateColor(paletteDOM.childNodes[1], this.state.colorHEX)
		this.updateColor(paletteDOM.childNodes[2], colorDark)
	}
	generateColor() {
		let red = this.randomNumber(0, 255)
		let blue = this.randomNumber(0, 255)
		let green = this.randomNumber(0, 255)
		this.setState({
			color: {
				r: red,
				g: green,
				b: blue
			}
		})
	}
	randomNumber(min, max){
	    return Math.round(Math.random() * (max - min) + min)
	}
	updateColor(element, color) {
		element.childNodes[0].innerHTML = color
		element.style.backgroundColor = color
	}
	render() {
		return (
			<div className="page">
				<button className="button" onClick={() => this.generatePalette()} >Nouvelle palette</button>
				<div className="palette">
					<div className="lighter"><span className="hexcode">#484C84</span></div>
					<div className="regular"><span className="hexcode">{this.state.colorHEX}</span></div>
					<div className="darker"><span className="hexcode">#484C84</span></div>
				</div>
				<div className="pickers">
				<MaterialPicker color={this.state.colorHEX} onChangeComplete={this.handleChange} />
				<ChromePicker color={this.state.colorHEX} onChangeComplete={this.handleChange} />
				</div>
			</div>
		);
	}
}
export default App;
